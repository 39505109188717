import React from "react";
import "./style.css";

function About() {
  return (
    <>
      <section class="text-left bg--secondary">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-lg-12">
              <h2>About</h2>
              <p class="lead text-justify">
                Too often, many captivating stories in the outdoor community go
                untold. We are dedicated to sharing them via online film
                festivals. We strive to give everyone an opportunity to use
                their voice and hope to create a more equitable outdoors.
              </p>
            </div>
          </div>
          {/* <!--end of row--> */}
        </div>
        {/* <!--end of container--> */}
      </section>
      <section className="bg--dark">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-10 col-lg-12">
              <h4>
                <b>Our Story</b>
              </h4>
              <p className="lead text-justify">
                OWNYOUR Media was founded in the summer of 2020, during the
                coronavirus pandemic, the surge of protests following the murder
                of the late George Floyd, and the constant battle for equity in
                the outdoor industry. Our Co-Founder, Mikhail Martin was
                inspired by these recent events to create an opportunity for
                long lasting change in the outdoor industry. He wanted to create
                something that all members of this community can enjoy, while
                shedding light on the experiences that have gone unnoticed and
                overlooked. Thus came the idea for a series of film festivals,
                all dedicated to giving individuals who love the outdoors a
                platform to share their voices, images, and stories. With the
                expertise of a hardworking business student, our Co-Founder Asia
                Irons, and a talented designer Andy Lu, OWNYOUR Media was
                formed.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bg--secondary">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="feature">
                <h4>
                  <b>OWNERSHIP</b>
                </h4>
                <p>
                  With rich modal and notification functionality and a robust
                  suite of options, Stack makes building feature-heavy pages
                  simple and enjoyable.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature">
                <h4>
                  <b>EMPOWERMENT</b>
                </h4>
                <p>
                  Drastically reduce the time it takes to move from initial
                  concept to production-ready with Stack and Variant Page
                  Builder. Your clients will love you for it!
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="feature">
                <h4>
                  <b>CREATIVITY</b>
                </h4>
                <p>
                  For anyone who intends on submitting a film, we want to be
                  clear that you should use your creativity! It’s your story,
                  and you should tell it exactly how you want to. Your films can
                  be nonfiction, fictional, animated, whatever you wish. All
                  stories are appreciated for how they are told.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="switchable feature-large">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <img
                alt="Mikhail Martin"
                className="border--round"
                src="/static/img/bios/mikhail_martin.jpg"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="switchable__text">
                <div className="text-block">
                  <h2>Mikhail Martin</h2> <span>Co-Founder &amp; CEO</span>
                </div>
                <p className="lead text-justify">
                  My name is Mikhail Martin and one of my missions in life is to
                  create as many opportunities as possible for my community in
                  the outdoors. Previously, I co-founded BOC (Brothers of
                  Climbing) and The Color the Crag Climbing Festival. I have
                  also worked with many companies and organizations to tackle
                  diversity issues within the outdoor industry. I look forward
                  to continuing the work with OWNYOUR Media.
                </p>
                <ul className="social-list list-inline list--hover">
                  <li className="list-inline-item">
                    <a href="mailto:mikhail@ownyour.media">
                      <i className="socicon socicon-mail icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/kaledoesit">
                      <i className="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/mikhailmartin/">
                      <i className="socicon socicon-linkedin icon icon--xs"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="switchable feature-large switchable--switch">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <img
                alt="Asia Irons"
                className="border--round"
                src="/static/img/bios/asia_irons.jpg"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="switchable__text">
                <div className="text-block">
                  <h2>Asia Irons</h2> <span>Co-Founder &amp; COO</span>
                </div>
                <p className="lead text-justify">
                  My name is Asia Irons and I am an 18-year-old student at the
                  Howard University School of Business. I started the OWNYOUR
                  Media team to use my platform to give others a voice, and will
                  hopefully learn a lot more about the outdoors community. One
                  of my biggest dreams is to help others achieve their dreams
                </p>
                <ul className="social-list list-inline list--hover">
                  <li className="list-inline-item">
                    <a href="mailto:asia@ownyour.media">
                      <i className="socicon socicon-mail icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/28slaysia/">
                      <i className="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/asia-irons/">
                      <i className="socicon socicon-linkedin icon icon--xs"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="switchable feature-large">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <img
                alt="Andy Lu"
                className="border--round"
                src="/static/img/bios/andy_lu.jpg"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="switchable__text">
                <div className="text-block">
                  <h2>Andy Lu</h2> <span>Design Lead</span>
                </div>
                <p className="lead text-justify">
                  My name is Andy Lu. I’m a designer born and raised in NYC. In
                  my professional experience, I’ve typically worked on
                  advertising for large brands. However, I’m hoping to now
                  direct my energy and skills towards building a more diverse
                  community in the outdoors.
                </p>
                <ul className="social-list list-inline list--hover">
                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/andylu.png">
                      <i className="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/andyludesign/">
                      <i className="socicon socicon-linkedin icon icon--xs"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="switchable feature-large switchable--switch">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <img
                alt="Asia Irons"
                className="border--round"
                src="/static/img/bios/esther.jpg"
              />
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="switchable__text">
                <div className="text-block">
                  <h2>Esther-Clare Dionisio</h2> <span>Marketing Manager</span>
                </div>
                <p className="lead text-justify">
                  My name is Esther-Clare Dionisio. Although I studied cinema in
                  college, my career began at the front desk of a rock climbing
                  gym. As I got more involved with the NYC climbing community, I
                  was able to progress towards Social Media and Marketing roles.
                  I’m so excited to be part of the OWNYOUR Media team. With my
                  cinema background and marketing skills, I hope to help push
                  the UNSTOPPABLE Film Fest forward!
                </p>
                <ul className="social-list list-inline list--hover">
                  {/* <li className="list-inline-item">
                    <a href="mailto:asia@ownyour.media">
                      <i className="socicon socicon-mail icon icon--xs"></i>
                    </a>
                  </li> */}

                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/ececlare/">
                      <i className="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/in/esther-dionisio/">
                      <i className="socicon socicon-linkedin icon icon--xs"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.twitch.tv/ececlare">
                      <i className="socicon socicon-twitch icon icon--xs"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
