import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./style.css";
import { Footer } from "../Footer";
import { Home } from "../Home";
import { About } from "../About";
import { Contact } from "../Contact";
import { Events } from "../Events";
import { Navigation } from "../Navigation";
import { ProfileModule } from "../ProfileModule";
import { Merch } from "../Merch";

function App() {
  return (
    <>
      <Navigation />
      <div className="main-container">
        <Router>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/merch">
              <Merch />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/events/unstoppable">
              <Events />
            </Route>
            <Route path="/faqs">
              <Contact />
            </Route>
            <Route path="/profile">
              <ProfileModule />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
