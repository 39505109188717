/* eslint-disable no-undef */
import React from "react";
import ScriptTag from "react-script-tag";

const ShopifyTag = () => {
  return (
    <>
      <div id="collection-component-1608137451293"></div>
      <ScriptTag isHydrating={true} type="text/javascript">
        {(function () {
          var scriptURL =
            "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
          if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
              ShopifyBuyInit();
            } else {
              loadScript();
            }
          } else {
            loadScript();
          }
          function loadScript() {
            var script = document.createElement("script");
            script.async = true;
            script.src = scriptURL;
            (
              document.getElementsByTagName("head")[0] ||
              document.getElementsByTagName("body")[0]
            ).appendChild(script);
            script.onload = ShopifyBuyInit;
          }
          function ShopifyBuyInit() {
            var client = ShopifyBuy.buildClient({
              domain: "ownyour-media-shop.myshopify.com",
              storefrontAccessToken: "93973e9bbc39916c708fd8ee0aaf1ddb",
            });
            ShopifyBuy.UI.onReady(client).then(function (ui) {
              ui.createComponent("collection", {
                id: "233689120932",
                node: document.getElementById(
                  "collection-component-1608137451293"
                ),
                moneyFormat: "%24%7B%7Bamount%7D%7D",
                options: {
                  product: {
                    styles: {
                      product: {
                        "@media (min-width: 601px)": {
                          "max-width": "calc(50% - 40px)",
                          "margin-left": "40px",
                          "margin-bottom": "50px",
                          width: "calc(50% - 40px)",
                        },
                        img: {
                          height: "calc(100% - 15px)",
                          position: "absolute",
                          left: "0",
                          right: "0",
                          top: "0",
                        },
                        imgWrapper: {
                          "padding-top": "calc(75% + 15px)",
                          position: "relative",
                          height: "0",
                        },
                      },
                      button: {
                        color: "#000000",
                        ":hover": {
                          color: "#000000",
                          "background-color": "#3dc128",
                        },
                        "background-color": "#44d62c",
                        ":focus": {
                          "background-color": "#3dc128",
                        },
                        "border-radius": "0px",
                      },
                    },
                    buttonDestination: "modal",
                    contents: {
                      options: false,
                    },
                    text: {
                      button: "Product Details",
                    },
                  },
                  productSet: {
                    styles: {
                      products: {
                        "@media (min-width: 601px)": {
                          "margin-left": "-40px",
                        },
                      },
                    },
                  },
                  modalProduct: {
                    contents: {
                      img: false,
                      imgWithCarousel: true,
                      button: false,
                      buttonWithQuantity: true,
                    },
                    styles: {
                      product: {
                        "@media (min-width: 601px)": {
                          "max-width": "100%",
                          "margin-left": "0px",
                          "margin-bottom": "0px",
                        },
                      },
                      button: {
                        color: "#000000",
                        ":hover": {
                          color: "#000000",
                          "background-color": "#3dc128",
                        },
                        "background-color": "#44d62c",
                        ":focus": {
                          "background-color": "#3dc128",
                        },
                        "border-radius": "0px",
                      },
                    },
                    text: {
                      button: "Add to cart",
                    },
                  },
                  cart: {
                    styles: {
                      button: {
                        color: "#000000",
                        ":hover": {
                          color: "#000000",
                          "background-color": "#3dc128",
                        },
                        "background-color": "#44d62c",
                        ":focus": {
                          "background-color": "#3dc128",
                        },
                        "border-radius": "0px",
                      },
                    },
                    text: {
                      total: "Subtotal",
                      button: "Checkout",
                    },
                  },
                  toggle: {
                    styles: {
                      toggle: {
                        "background-color": "#44d62c",
                        ":hover": {
                          "background-color": "#3dc128",
                        },
                        ":focus": {
                          "background-color": "#3dc128",
                        },
                      },
                      count: {
                        color: "#000000",
                        ":hover": {
                          color: "#000000",
                        },
                      },
                      iconPath: {
                        fill: "#000000",
                      },
                    },
                  },
                },
              });
            });
          }
        })()}
      </ScriptTag>
    </>
  );
};

export default ShopifyTag;
