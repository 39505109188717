import React from "react";
import "./style.css";

function Contact() {
  return (
    <>
      <section className="text-left bg--dark">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-12">
              <h2>Contact Us</h2>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-6">
              {/* <form
                className="form-email row mx-0"
                action="https://formspree.io/myynodzv"
                method="POST"
              >
                <div className="col-md-6 col-12">
                  <label>Your Name:</label>
                  <input
                    type="text"
                    name="name"
                    className="validate-required"
                  />
                </div>
                <div className="col-md-6 col-12">
                  <label>Email Address:</label>
                  <input
                    type="email"
                    name="email"
                    className="validate-required validate-email"
                  />
                </div>
                <div className="col-md-12 col-12">
                  <label>Message:</label>
                  <textarea
                    rows="4"
                    name="Message"
                    className="validate-required"
                  ></textarea>
                </div>
                <div className="col-md-5 col-lg-4 col-6">
                  <button
                    type="submit"
                    className="btn type--uppercase"
                  >
                    Send Message
                  </button>
                </div>
              </form> */}
              <h3>EMAIL: info@ownyour.media</h3>
            </div>
            <div className="col-md-6 col-lg-6">
              <img
                src="/static/img/banners/OYM_Homepage_3-ImageBanner.png"
                class="img-fluid rounded"
                alt="Climber bouldering on rock"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="bg--secondary">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="text-block">
                <h4>Frequently Asked Questions</h4>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-block">
                <h5>Who is behind OWNYOUR Media?</h5>
                <p className="text-justify">
                  OWNYOUR Media is a media company founded by Mikhail Martin,
                  co-founder of BOC(Brothers of Climbing) and Color the Crag
                  Climbing Festival and Asia Irons, business student at Howard
                  University. Our mission is to share the often untold stories
                  of the outdoor community through film.
                </p>
              </div>
              <div className="text-block">
                <h5>Where can I attend your festivals?</h5>
                <p className="text-justify">
                  All OWNYOUR Media film festivals are available online. The
                  events are streamed through our platform, which will be
                  located directly on our website. No need to buy expensive
                  plane tickets, or take time out of your busy schedule for
                  traveling purposes. Our festival can be accessed from the
                  comfort of your own home.
                </p>
              </div>
              <div className="text-block">
                <h5>When is your next festival?</h5>
                <p className="text-justify">
                  UNSTOPPABLE FILM FEST will take place on December 12, 2020.
                  Future festivals are currently being planned for 2021. Stay
                  tuned for more details!
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-block">
                <h5>How do I submit my films?</h5>
                <p className="text-justify">
                  If you are interested in submitting a film for UNSTOPPABLE
                  FILM FEST, visit our Event page and click the “Submit Your
                  Film” button, or &nbsp;
                  <a href="https://filmfreeway.com/unstoppablefilmfest">
                    visit here
                  </a>
                  . This link will redirect you to our Film Freeway page, which
                  includes all requirements for submissions.
                </p>
              </div>
              <div className="text-block">
                <h5>How can I show my support?</h5>
                <p className="text-justify">
                  There are many ways to support OWNYOUR Media. For starters,
                  follow us on social media and share with your friends and
                  family. Organizations and companies should inquire about
                  sponsoring and advertising. Once available, fund our
                  Kickstarter campaign and purchase our merch. Most important,
                  purchase tickets to our festivals. OWNYOUR Media is incredibly
                  grateful for any and all of your support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
