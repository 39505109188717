/* eslint-disable no-undef */

import React from "react";
import ShopifyTag from "./Shopify";
import "./style.css";

function Merch() {
  return (
    <>
      <section class="text-left bg--secondary">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-lg-12">
              <h2>Merch</h2>
              <p class="lead text-justify">
                Have you enjoyed any of our events? Looking for a way to support
                ONWYOUR Media? Picking up our merch is one way to show some
                love!
              </p>
            </div>
          </div>
          {/* <!--end of row--> */}
        </div>
        {/* <!--end of container--> */}
      </section>
      <section>
        <div className="container">
          <ShopifyTag />
        </div>
      </section>
    </>
  );
}

export default Merch;
