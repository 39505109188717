/* eslint-disable no-undef */

import React from "react";
import "./style.css";

function Events() {
  return (
    <>
      <section className="cover height-80 imagebg text-center" data-overlay="5">
        <div className="background-image-holder background--top">
          <img
            alt="background"
            src="/static/img/banners/OYM_EventsPage_1-Header.jpg"
          />
        </div>
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-md-8">
              <img
                alt="headline"
                className="unmarg--bottom"
                src="/static/img/logos/unstoppable_logo_ff_green.png"
              />
              <br /> <br />
              <h3>DECEMBER 12, 2020</h3>
              <h3>6:00 PM EST</h3>
              {/* <a
                className="btn type--uppercase"
                href=" https://www.kickstarter.com/projects/beenhereff/unstoppable-film-fest"
                style={{ backgroundColor: "FFE800" }}
              >
                <span className="btn__text">FUND OUR KICKSTARTER</span>
              </a> */}
              <a className="btn type--uppercase" href="/merch">
                <span className="btn__text">SHOP MERCH</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-12">
              <h4>UNSTOPPABLE</h4>
              <p className="lead">
                UNSTOPPABLE FILM FEST's mission is to share the work, stories,
                and adventures of BIPOC in the outdoors. Adventure is whatever
                you make of it. For some it can mean recreating in the outdoors
                and nature. For others it can mean protesting for social
                justice. Films submitted can be nonfictional, fictional, or even
                animated. What is most important is for the community to be able
                to express themselves no matter who they are.
              </p>
              <p className="lead">
                UNSTOPPABLE is not your average film festival. This event will
                be streamed live on{" "}
                <a href="https://www.youtube.com/channel/UCQ_xae8RZryLS9CFwuuvE1A">
                  YouTube
                </a>
                . In light of the COVID-19 pandemic, we hope this film festival
                will allow the outdoor community to come together from the
                safety of their own homes. UNSTOPPABLE is a one-day event, split
                into multiple sessions. Each session will stream a series of
                submitted films, which will be followed by live Q&A forums
                featuring those whose story is told and film creators.
              </p>
              <br />
              <hr />
              <div
                className="slider slider--inline-arrows slider--arrows-hover text-center"
                data-arrows="true"
              >
                <ul className="slides">
                  <li className="col-md-3 col-6">
                    <img
                      alt="partner 1"
                      className="image--xs"
                      src="https://www.evolvsports.com/media/vector/60/e4/db/evolv_logo.svg"
                    />
                  </li>
                  <li className="col-md-3 col-6">
                    <img
                      alt="partner 2"
                      className="image--xs"
                      src="https://cdn.shopify.com/s/files/1/0642/8893/t/9/assets/braindead-logo.png?v=60200532047838266"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg--dark">
        <div className="container">
          <h4>SCHEDULE</h4>
          <table class="border--round table--alternate-row">
            <thead>
              <tr>
                <th>START TIME</th>
                <th>FEATURE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6:00 PM EST</td>
                <td>Introductions</td>
              </tr>
              <tr>
                <td>6:05 PM EST</td>
                <td>Water Flows Together</td>
              </tr>
              <tr>
                <td>6:20 PM EST</td>
                <td>Lady First</td>
              </tr>
              <tr>
                <td>6:40 PM EST</td>
                <td>5.12B “Satanic Mechanic”</td>
              </tr>
              <tr>
                <td>6:50 PM EST</td>
                <td>Do Better Together</td>
              </tr>
              <tr>
                <td>7:00 PM EST</td>
                <td>Shelma Jun: Interview</td>
              </tr>
              <tr>
                <td>7:15 PM EST</td>
                <td>Detroit Hives</td>
              </tr>
              <tr>
                <td>7:25 PM EST</td>
                <td>A Word From Ashima Shiraishi</td>
              </tr>
              <tr>
                <td>7:30 PM EST</td>
                <td>
                  The Kilele Life: A Film About The Mountaineers of East Africa
                </td>
              </tr>
              <tr>
                <td>8:05 PM EST</td>
                <td>The Prodigy</td>
              </tr>
              <tr>
                <td>8:10 PM EST</td>
                <td>The People of Climbing</td>
              </tr>
              <tr>
                <td>8:30 PM EST</td>
                <td>Josh Greenwood: Interview</td>
              </tr>
              <tr>
                <td>8:50 PM EST</td>
                <td>A Word From Our Team</td>
              </tr>
              <tr>
                <td>8:55 PM EST</td>
                <td>Afghan Cycles</td>
              </tr>
              <tr>
                <td>8:30 PM EST</td>
                <td>Josh Greenwood: Interview</td>
              </tr>
              <tr>
                <td>10:30 PM EST</td>
                <td>Closing</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section>
        <div className="container">
          <h4>ACCESSIBILITY INFORMATION</h4>
          <h5>How can this event be accessed?</h5>
          <p>
            We are will be streamed via{" "}
            <a href="https://www.youtube.com/channel/UCQ_xae8RZryLS9CFwuuvE1A">
              YouTube
            </a>
            . Use the link provided in email after RSVP. To learn more about
            YouTube’s accessibility, please visit their{" "}
            <a href="https://www.google.com/accessibility/products-features/">
              website
            </a>
            .
          </p>
          <h5>What is the event format?</h5>
          <p>
            This event will include a series of films and live discussions.
            There will be short breaks inbetween segments.
          </p>
          <h5>Will there be a sign interpreter available for the event?</h5>
          <p>
            No, sign interpreters will not be available. Closed captions will be
            available during films. Live sessions of the festival offer computer
            generated closed captions.
          </p>
          <h5>If I need assistance, who should I contact?</h5>
          <p>
            Email info@ownyour.media if there are any questions you have before,
            during, or after the event. Also, feel free to use the YouTube chat
            feature.
          </p>
        </div>
      </section>
      <section className="bg--dark">
        <div className="container">
          <h4>TIMELINE</h4>
          <div className="process-2 row">
            <div className="col-md-2">
              <div className="process__item">
                <h5>AUGUST 3, 2020</h5>
                <p>Film submission open</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="process__item">
                <h5>OCTOBER 9, 2020</h5>
                <p>Deadline for early film submissions.</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="process__item">
                <h5>NOVEMBER 13, 2020</h5>
                <p>Ticket sales begin</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="process__item">
                <h5>NOVEMBER 13, 2020</h5>
                <p>Final deadline for film submissions.</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="process__item">
                <h5>NOVEMBER 20, 2020</h5>
                <p>Film selections are announced.</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="process__item">
                <h5>DECEMBER 12, 2020</h5>
                <p>An action packed day of films.</p>
              </div>
            </div>
          </div>
          <div className="row">*Dates subject to change</div>
        </div>
      </section>
      <section className="text-center imagebg" data-overlay="4">
        <div className="background-image-holder">
          <img
            alt="background"
            src="/static/img/banners/OYM_EventsPage_3-CTA-Banner.jpg"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-6"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Events;
