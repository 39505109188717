import React from "react";

function ProfileModule() {
  return (
    <div>
      <h3>ProfileModule</h3>
    </div>
  );
}

export default ProfileModule;
