import React from "react";
import "./style.css";

function Home() {
  return (
    <>
      <section class="cover height-80 imagebg text-center" data-overlay="4">
        <div
          class="background-image-holder"
          style={{
            background: 'url("/static/img/banners/OYM_Homepage_1-Header.png")',
            opacity: 1,
          }}
        >
          <img
            alt="background"
            src="/static/img/banners/OYM_Homepage_1-Header.png"
          />
        </div>
        <div class="container pos-vertical-center">
          <div class="row">
            <div class="col-md-8 col-lg-7">
              <h1>
                OUR MISSION IS TO TELL THE STORIES OF THE OUTDOORS OFTEN UNTOLD
              </h1>
              <a class="btn type--uppercase" href="/about">
                <span class="btn__text">LEARN MORE</span>
              </a>
            </div>
          </div>
          {/* <!--end of row--> */}
        </div>
        {/* <!--end of container--> */}
      </section>
      <section className="cover height-80 imagebg text-center" data-overlay="5">
        <div className="background-image-holder background--top">
          <img
            alt="background"
            src="/static/img/banners/OYM_EventsPage_1-Header.jpg"
          />
        </div>
        <div className="container pos-vertical-center">
          <div className="row">
            <div className="col-md-8">
              <img
                alt="headline"
                className="unmarg--bottom"
                src="/static/img/logos/unstoppable_logo_ff_green.png"
              />
              <br /> <br />
              <h3>
                FILM FEST FOCUSED ON SHARING THE EXPERIENCES OF BIPOC IN THE
                OUTDOORS IN FRONT AND BEHIND THE CAMERA
              </h3>
              <div className="col-xs-3">
                <a
                  className="btn type--uppercase"
                  href="/events/unstoppable"
                  style={{ margin: "0px" }}
                >
                  <span className="btn__text">LEARN MORE</span>
                </a>
                &nbsp;
                <a
                  className="btn type--uppercase"
                  href="https://unstoppable-film-fest-2020.eventbrite.com"
                  style={{ margin: "0px" }}
                >
                  <span className="btn__text">RSVP NOW</span>
                </a>
                {/* <a
                  className="btn type--uppercase"
                  href=" https://www.kickstarter.com/projects/beenhereff/unstoppable-film-fest"
                  style={{ backgroundColor: "FFE800" }}
                >
                  <span className="btn__text">FUND OUR KICKSTARTER</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-center ">
        <div className="container">
          <div className="row mb-4 justify-content-center">
            <h2>Join Our Mailing List</h2>
          </div>

          <form
            className="row justify-content-center"
            data-success="Thanks for signing up.  Please check your inbox for a confirmation email."
            data-error="Please provide your email address and agree to the terms."
            action="https://tinyletter.com/ownyourmedia"
            method="post"
            target="popupwindow"
            onsubmit="window.open('https://tinyletter.com/ownyourmedia', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
          >
            <div className="col-lg-6 col-md-8">
              <input
                className="validate-required validate-email"
                type="text"
                name="email"
                placeholder="Enter your email for our monthly newsletter"
                id="tlemail"
              />
            </div>

            <div className="col-lg-2 col-md-4">
              <button type="submit" className="btn">
                Subscribe Now
              </button>
            </div>
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_77142ece814d3cff52058a51f_f300c9cce8"
                tabIndex="-1"
                value=""
              />
            </div>
          </form>
        </div>
        {/* <!--end of container--> */}
      </section>
      <section className="height-80 parallax imagebg">
        <div className="background-image-holder">
          <img
            alt="background"
            src="static/img/banners/OYM_Homepage_2-ImageBanner.png"
          />
        </div>
      </section>
      {/* <section className="text-center bg--secondary">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10">
              <div className="slider" data-paging="true">
                <ul className="slides">
                  <li>
                    <div className="testimonial">
                      <span className="h3">
                        &ldquo;There's a feeling of structure that you can't
                        find in other templates &mdash; Stack has become my
                        default template.&rdquo;
                      </span>
                      <h5>Sam Briggs</h5>
                      <span>Envato Customer</span>
                    </div>
                  </li>
                  <li>
                    <div className="testimonial">
                      <span className="h3">
                        &ldquo;Variant has been a massive plus for my workflow
                        &mdash; I can now get live mockups out in a matter of
                        hours, my clients really love it.&rdquo;
                      </span>
                      <h5>Rob Vasquez</h5>
                      <span>Interface Designer &mdash; Yoke</span>
                    </div>
                  </li>
                  <li>
                    <div className="testimonial">
                      <span className="h3">
                        &ldquo;We’ve been using Stack to prototype designs
                        quickly and efficiently. Needless to say we’re hugely
                        impressed by the style and value.&rdquo;
                      </span>
                      <h5>Maguerite Holland</h5>
                      <span>Interface Designer &mdash; Yoke</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--end of row-->
        </div>
        <!--end of container-->
      </section> */}
    </>
  );
}

export default Home;
