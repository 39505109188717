import React from "react";
import "./style.css";

function Political() {
  return (
    <footer className="footer-7 text-center-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <span className="type--fine-print">
              © <span className="update-year"></span> OWNYOUR Media, LLC - All
              Rights Reserved
            </span>
          </div>
          <div className="col-sm-6 text-right text-center-xs">
            <ul className="social-list list-inline">
              <li>
                <a href="https://instagram.com/ownyour.media">
                  <i className="socicon socicon-instagram icon icon--xs"></i>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCQ_xae8RZryLS9CFwuuvE1A">
                  <i className="socicon socicon-youtube icon icon--xs"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Political;
