import React from "react";
import "./style.css";

function Navigation() {
  return (
    <div className="nav-container">
      <div className="bar bar--sm visible-xs">
        <div className="container">
          <div className="row">
            <div className="col-3 col-md-2">
              <a href="/">
                <img
                  className="logo logo-dark"
                  alt="logo"
                  src="/static/img/logos/ownyourmedia_logo_black.png"
                />
                <img
                  className="logo logo-light"
                  alt="logo"
                  src="/static/img/logos/ownyourmedia_logo_light.png"
                />
              </a>
            </div>
            <div className="col-9 col-md-10 text-right">
              <a
                href="#"
                className="hamburger-toggle"
                data-toggle-class="#menu2;hidden-xs hidden-sm"
              >
                <i className="icon icon--sm stack-interface stack-menu"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <nav id="menu2" className="bar bar-2 hidden-xs">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 text-left-sm hidden-xs order-lg-2">
              <div className="bar__module">
                <a href="/">
                  <img
                    className="logo logo-dark"
                    alt="logo"
                    src="/static/img/logos/ownyourmedia_logo_black.png"
                  />
                  <img
                    className="logo logo-light"
                    alt="logo"
                    src="/static/img/logos/ownyourmedia_logo_white.png"
                  />
                </a>
              </div>
            </div>
            <div className="col-lg-5 order-lg-1 order-md-2">
              <div className="bar__module">
                <ul className="menu-horizontal text-left">
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/merch">Merch</a>
                  </li>
                  <li className="dropdown">
                    <span className="dropdown__trigger">Events</span>
                    <div className="dropdown__container">
                      <div className="container">
                        <div className="row">
                          <div className="dropdown__content col-lg-2">
                            <ul className="menu-vertical">
                              <li>
                                <a href="/events/unstoppable">UNSTOPPABLE</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 text-right text-left-xs text-left-sm text-center-md order-lg-3 hidden-sm">
              <div className="bar__module">
                <a
                  className="btn btn--sm type--uppercase"
                  href="https://youtube.ownyour.media"
                >
                  <span className="btn__text">SUBSCRIBE TO OUR CHANNEL</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
